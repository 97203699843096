import Places from 'places.js'

let containerAddress = document.querySelector('#address_form')
if (containerAddress !== null) {
    let place = Places({
        container: document.querySelector('#address_form_address1'),
        templates: {
            value: function (suggestion) {
                return suggestion.name;
            }
        }
    })
    place.on('change', e => {
        document.querySelector('#address_form_city').value = e.suggestion.city
        document.querySelector('#address_form_postalCode').value = e.suggestion.postcode
        document.querySelector('#address_form_lat').value = e.suggestion.latlng.lat
        document.querySelector('#address_form_lng').value = e.suggestion.latlng.lng
    })
}


let containerCompanyAddress = document.querySelector('#company_address_form')
if (containerCompanyAddress !== null) {
    let place = Places({
        container: document.querySelector('#company_address_address1'),
        templates: {
            value: function (suggestion) {
                return suggestion.name;
            }
        }
    })
    place.on('change', e => {
        document.querySelector('#company_address_city').value = e.suggestion.city
        document.querySelector('#company_address_postalCode').value = e.suggestion.postcode
        document.querySelector('#company_address_lat').value = e.suggestion.latlng.lat
        document.querySelector('#company_address_lng').value = e.suggestion.latlng.lng
    })
}

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
require('../css/admin.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// require jQuery normally
global.$ = global.jQuery = $;

require('bootstrap');


$('ol.ol-counter li').on('click', function () {
    let data = $(this).data('target');
    $('ol.ol-counter li').removeClass('active');
    $(this).addClass('active');
    $('.ol-content').fadeOut();
    $(data).fadeIn();
    console.log(data);
});

jQuery(document).ready(function() {
    // grab the initial top offset of the navigation
    var stickyNavTop = jQuery('.sticky-nav').offset().top;

    // our function that decides weather the navigation bar should have "fixed" css position or not.
    var stickyNav = function () {
        var scrollTop = jQuery(window).scrollTop(); // our current vertical position from the top

        // if we've scrolled more than the navigation, change its position to fixed to stick to top,
        // otherwise change it back to relative
        if (scrollTop > stickyNavTop) {
            jQuery('.sticky-nav').addClass('sticky');
            jQuery('.sticky-nav').removeClass('container');
            jQuery('.sticky-nav').addClass('container-fluid');
        } else {
            jQuery('.sticky-nav').removeClass('sticky');
            jQuery('.sticky-nav').removeClass('container');
            jQuery('.sticky-nav').addClass('container');
        }
    };

    stickyNav();
    // and run it again every time you scroll
    jQuery(window).scroll(function () {
        stickyNav();
    });
});
